import React, { useEffect, useState } from 'react';
import './selectprinter.css'
import { getDatabase, ref,get, set ,child,onValue,update, remove,push} from "firebase/database";
import app from "../../../../database/firebase"
import LoadingView from '../../../../Views/Loading/loading';
import {getAuth} from 'firebase/auth'
import { NavLink, useNavigate } from 'react-router-dom';

function loadRazorpay(src){
  return new Promise((resolve)=>{
    const script=document.createElement('script');
    script.src=src
    
    script.onload=()=>{
      resolve(true);
    }
    script.onerror=()=>{
      resolve(false);
      alert("something went wrong,try again later")
    }
    document.body.appendChild(script);
  })
}
function ItemSelection({ printermainid,printer,items,totalCost, onItemSelect, onClose ,proceed=false}) {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedItem, setSelectedItem] = useState(printer["id"]);
  const [printers,setPrinters]=useState(null);
  const [razorpayInstance, setRazorpayInstance] = useState(null);
  const [loading, setLoading] = useState(false);  
  
  useEffect(()=>{

    onValue(ref(getDatabase(app),"Printers"),(snapshot)=>{
      var printers=[];
      snapshot.forEach((childSnapshot) => {
        const childKey = childSnapshot.key;
        const childData = childSnapshot.val();
        printers.push({id:childKey,name:childData["details"]["name"],description:childData["details"]["description"],status:childData["details"]["status"],status_message:childData["details"]["status_message"]});
        //...
      });
      setPrinters(printers);
    })
  },[]);
  const handleItemClick = (item) => {
    setSelectedItem(item.name);
    set(ref(getDatabase(app),"users/"+getAuth(app).currentUser.uid+"/details/printer"),item.id)


    get(ref(getDatabase(app),"Printers/"+item.id+"/details/properties")).then((snapshot)=>{
      var properties=snapshot.val();
      var p={}
      for(let key of Object.keys(properties)){
        p[key]=properties[key][0]["name"];
      }
      get(ref(getDatabase(app),"users/"+getAuth(app).currentUser.uid+"/printstodo")).then((snapshot)=>{
        if(snapshot.val()){
          var files=Object.keys(snapshot.val());
            files.forEach((file)=>{
              set(ref(getDatabase(app),"users/"+getAuth(app).currentUser.uid+"/printstodo/"+file+"/properties"),p)
          })
        }
        })
    })
    onClose()
  };
  async function displayRazorpay(printer){
    setLoading(true);

    const res=await loadRazorpay("https://checkout.razorpay.com/v1/checkout.js");
    if(!res){
      alert("error loading checkout page");
      setLoading(false);
      console.log("clear");
      return;
    }
    const data =await fetch(process.env.REACT_APP_BACKEND_URL+"/razorpay",{method: 'POST',headers: {
      'Content-Type': 'application/json', // Specifies JSON content type
    },body: JSON.stringify({ amount: totalCost })}).then((t)=>
          t.json()
          );
    
    if(!data){
        alert("something went wrong");
        setLoading(false);
        console.log("clear");return;
    }
    const uid=getAuth(app).currentUser.uid;
    const r=ref(getDatabase(app),"users/"+uid+"/printstodo")
    get(r).then((snapshot)=>{
    if(data){
      const data1=snapshot.val()
      const db = getDatabase(app);
          const orderId = data.id;
          const orderRef = ref(db, `orders/${orderId}`);
          const auth = getAuth(app);
          const userId = auth.currentUser ? auth.currentUser.uid : null;
          set(ref(db, `orders/${orderId}/user`), userId);
          set(ref(db, `orders/${orderId}/printer`), printer);
      Object.keys(data1).forEach((key)=>{
        set(child(r,key+"/printer"),printer)
        set(child(r,key+"/order_id"),data.id)
      })
    }})
    const options = {
      "key": process.env.REACT_APP_BACKEND_RAZORPAY_KEY_ID, // Enter the Key ID generated from the Dashboard
      "amount": data.amount.toString(), // Amount is in curr ency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      "currency": data.currency,
      "name": "AUTOPrint", //your business name
      "description": "Make Payement",
      "image": "https://firebasestorage.googleapis.com/v0/b/skilldevelopmentapp.appspot.com/o/images%2F3.png?alt=media&token=e69d4d4c-01a4-43b4-8bd1-e689ff77aee5",
      "order_id": data.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
      "notes": {
          "address": "Atomte"
      },
      "redirect": true,
      "theme": {
          "color": "#3399cc"
      },
      handler: function(response){

        const paymentId = response.razorpay_payment_id;
        navigate("/autoprint/history")       
        onClose();
      }
    };
    const rzp1 = window.Razorpay(options);

    rzp1.on('payment.failed', function (response){
      setLoading(false);
      alert(response.error.description)
      

});
    setRazorpayInstance(rzp1);
    rzp1.open()



  }

  const handleDoneClick = () => {
    
    if (selectedItem) {
      if(proceed){
        if(printer["status"]){
          displayRazorpay(selectedItem)
        }else{
          alert("Printer is not active")
        }
        
      }else{

      
      onItemSelect(selectedItem);
      }
    }
  };

  return (
    <div className="item-selection-container">
      {printers!=null?
      <div className="item-selection">
        <h2>Select a Printer</h2>
        
        <input
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <ul>
          {printers
            .filter((item) =>
              (item.name+item.description+String(item.id)).toLowerCase().includes(searchTerm.toLowerCase())
            ).slice(0, 5)
            .map((item) => (
              
              <li key={item.id} onClick={() => handleItemClick(item)}>
                <input
                  type="radio"
                  id={`item-${item.id}`}
                  name="item"
                  checked={selectedItem === item.id}
                  onChange={() => handleItemClick(item)}
                />
                <div className="item-content">
                  <div className={`active-indicator ${(item.status=="Active") ? 'active' : 'inactive'}`}></div>
                  <div>
                    <label htmlFor={`item-${item.id}`}>{item.name}</label>
                    <p>{item.description}</p>
                  </div>
                </div>
              </li>
            ))}
        </ul>
        <div class="btns">
          {
        <button onClick={onClose}>
          Close
        </button>}
        {
          proceed?<button onClick={handleDoneClick} disabled={loading || !selectedItem}>
          {loading ? (
            <span className="spinner"></span> // Optional spinner
          ) : (
            "Proceed"
          )}
        </button>:<></>
        }
        
        
        </div>
        
      </div>:<LoadingView/>}

    </div>
  );
}
export default ItemSelection;


import React, { useState,useEffect } from 'react';
import { ref as dbRef,ref,get, getDatabase, push, set } from 'firebase/database';
import { ref as storageRef, uploadBytes, getDownloadURL, getStorage } from 'firebase/storage';
import './careeroptions.css' // We'll create this CSS file
import app from '../../database/firebase';
import LoadingView from '../../Views/Loading/loading';
const CareerOptions = () => {
  const [openModal, setOpenModal] = useState(false);
  const [currentJob, setCurrentJob] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
  });
  const [resume, setResume] = useState(null);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  
  const fetchJobs = async () => {
    const jobsRef = ref(getDatabase(app), 'careers'); // Assuming 'jobs' is the node in your database
    try {
      const snapshot = await get(jobsRef);
      if (snapshot.exists()) {
        const jobsData = snapshot.val();
        const formattedJobs = Object.entries(jobsData).map(([key, value]) => ({
          id: key,
          title: value.title,
          image: value.image,
          description: `${value.sd} ${value.fd}`, // Combining short and full descriptions
          sd: value.sd,
          fd: value.fd
        }));
        return formattedJobs;
      } else {
        console.log("No data available");
        return [];
      }
    } catch (error) {
      console.error("Error fetching jobs:", error);
      return [];
    }
  };

  const [jobs, setJobs] = useState([]);

  useEffect(() => {
    const getJobs = async () => {
      const fetchedJobs = await fetchJobs();
      setJobs(fetchedJobs);
    };

    getJobs();
  }, []);

  const handleOpenModal = (job) => {
    setCurrentJob(job);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setCurrentJob(null);
    setFormData({ name: '', email: '', phone: '' });
    setResume(null);
    setMessage('');
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      setResume(e.target.files[0]);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      // Upload resume to Firebase Storage
      const resumeStorageRef = storageRef(getStorage(app), `resumes/${Date.now()}_${resume.name}`);
      const uploadResult = await uploadBytes(resumeStorageRef, resume);
      const resumeUrl = await getDownloadURL(uploadResult.ref);

      // Save application data to Firebase Realtime Database
      const applicationsRef = dbRef(getDatabase(app), 'applications');
      const newApplicationRef = push(applicationsRef);
      await set(newApplicationRef, {
        ...formData,
        careerid: currentJob.id,
    
        resumeUrl,
        timestamp: Date.now(),
      });

      setMessage('Application submitted successfully!');
      setTimeout(handleCloseModal, 3000);
    } catch (error) {
      console.error('Error submitting application:', error);
      setMessage('Error submitting application. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="career-options">

      <h1>Career Opportunities</h1>
      {jobs.map((job) => (
        <div key={job.id} className="job-card">
          <h2>{job.title}</h2>
          <p>{job.description}</p>
          <button onClick={() => handleOpenModal(job)}>Apply</button>
        </div>
      ))}
      {
        jobs.length==0?
      <LoadingView ></LoadingView>:<></>
      }

      {openModal && (
        <div className="modal">
          <div className="modal-content">
            <h2>Apply for {currentJob?.title}</h2>
            <form onSubmit={handleSubmit}>
              <input
                type="text"
                name="name"
                placeholder="Name"
                value={formData.name}
                onChange={handleInputChange}
                required
              />
              <input
                type="email"
                name="email"
                placeholder="Email"
                value={formData.email}
                onChange={handleInputChange}
                required
              />
              <input
                type="tel"
                name="phone"
                placeholder="Phone Number"
                value={formData.phone}
                onChange={handleInputChange}
                required
              />
              <p>Upload your resume</p>
              <input
                type="file"
                accept=".pdf,.doc,.docx"
                onChange={handleFileChange}
                required
              />
              {resume && <p>{resume.name}</p>}
              <button type="submit" disabled={loading || !resume}>
                {loading ? 'Submitting...' : 'Submit Application'}
              </button>
            </form>
            {message && <p className="message">{message}</p>}
            <button onClick={handleCloseModal} className="close-btn">Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CareerOptions;

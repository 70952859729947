import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const float = keyframes`
  0% { transform: translateY(0px); }
  50% { transform: translateY(-20px); }
  100% { transform: translateY(0px); }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
  color: white;
  font-family: 'Arial', sans-serif;
`;

const Icon = styled.div`
  font-size: 80px;
  animation: ${float} 3s ease-in-out infinite;
`;

const Title = styled.h1`
  font-size: 36px;
  margin: 20px 0;
  text-align: center;
`;

const Description = styled.p`
  font-size: 18px;
  max-width: 600px;
  text-align: center;
  margin-bottom: 30px;
`;

const CountdownContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
`;

const CountdownItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CountdownValue = styled.span`
  font-size: 48px;
  font-weight: bold;
`;

const CountdownLabel = styled.span`
  font-size: 14px;
  text-transform: uppercase;
`;

const ComingSoonText = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-top: 30px;
  animation: ${fadeIn} 1s ease-in-out infinite alternate;
`;

const UpcomingFeature = () => {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  function calculateTimeLeft() {
    const difference = +new Date("2024-09-01") - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      };
    }

    return timeLeft;
  }

  return (
    <Container>
      <Icon>🚀</Icon>
      <Title>Exciting New Feature Coming Soon!</Title>
      <Description>
        Get ready for a game-changing update that will revolutionize your experience. 
        Our team has been working tirelessly to bring you something truly spectacular.
      </Description>
      <CountdownContainer>
        {Object.keys(timeLeft).map(interval => (
          <CountdownItem key={interval}>
            <CountdownValue>{timeLeft[interval]}</CountdownValue>
            <CountdownLabel>{interval}</CountdownLabel>
          </CountdownItem>
        ))}
      </CountdownContainer>
      <ComingSoonText>Stay tuned for the big reveal!</ComingSoonText>
    </Container>
  );
};

export default UpcomingFeature;
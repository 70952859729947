import React,{useRef,useState,useEffect} from 'react';

import Loading from '../../../../Views/Loading/loading'
import './profile.css';
import app from "../../../../database/firebase"
import { getAuth, reload, signOut } from "firebase/auth";
import { getDatabase, ref, onValue } from 'firebase/database';
import {useNavigate} from "react-router-dom"
import CircularProfileImage from '../../../../Views/CircularLetterImage/Circularimage';
function Profile({setIsOpen,menubtnRef,handleLogout}) {
    const [lodingView,setLoadingView]=useState(true);

    const navigate = useNavigate();
    const opencareers=()=>{
      navigate("/careers")
    }
    const openfeedback=()=>{
      navigate("/feedback")
    }
   

    const profileRef = useRef(null);
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (profileRef.current && !profileRef.current.contains(event.target) && !menubtnRef.current.contains(event.target)) {
          setIsOpen(false);
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [setIsOpen]);
    

    const [userData,setUserData] = useState({
        name:"",
        email:"",
        id:"",
        image:"",
    });
    useEffect(() => {
        getUserData(setUserData,setLoadingView);
    },[]);
  return (
    <div class="prof">
        {lodingView ? <Loading/> : <div ref={profileRef} className="profile-container">
      <div className="profile-info">
        {/* <img src={userData["image"]} alt="Profile" className="prof-image" /> */}
        {userData["image"]!=""?
        <img src={userData["image"]} alt="Profile" className='prof-image' />:<CircularProfileImage letters={userData["name"][0]!=null?userData["name"][0]:".."} size={70}/>}
        <div className="profile-details">
          <h2>{userData["name"]}</h2>
          <p>{userData["email"]}</p>
        </div>
      </div>
      <div className='more'>
      <button className="signout-btn" onClick={opencareers}>Careers</button>

      <button className="signout-btn" onClick={openfeedback}>Feedback</button>
      </div>
      <button className="signout-btn" onClick={handleLogout}>Sign Out</button>
    </div> }
    </div>
    
  );
}

function getUserData(setUserData,setLoadingView){
    const db = getDatabase(app);
      const dataRef = ref(db, 'users/'+getAuth(app).currentUser.uid+"/details"); // Replace 'your_database_path' with the path to your data in the Realtime Database

      onValue(dataRef, (snapshot) => {
        const fetchedData = snapshot.val();
        if (fetchedData) {
          setUserData({
            name:fetchedData["name"],
            email:fetchedData["email"],
            id:fetchedData.key,
            image:fetchedData["image"],
        }); // Convert object to array and set state
        setLoadingView(false); 
        } else {
          setLoadingView(false); 
        }
      });

}

export default Profile;
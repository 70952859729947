import React from 'react';

function Footer() {
  return (
    
    <footer>
      
      <div className="social-media">
      <a href="https://www.youtube.com/@Nishanth_Kantipudi" target="_blank" rel="noopener noreferrer">
        <i className="fab fa-youtube"></i>
      </a>
      <a href="https://x.com/nish_kantipudi" target="_blank" rel="noopener noreferrer">
        <i className="fab fa-twitter"></i>
      </a>
      <a href="https://www.linkedin.com/in/nishanth-kantipudi-b878602a7" target="_blank" rel="noopener noreferrer">
        <i className="fab fa-linkedin"></i>
      </a>
      </div>
      <div className="info">
      <p>Email : sainishanth.office@gmail.com</p>
        <p>&copy; 2024 Atomte. All rights reserved.</p>
        
      </div>
    </footer>
  );
}

export default Footer;

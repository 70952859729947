import {useState} from 'react'
import {getAuth} from "firebase/auth"
import {useNavigate} from "react-router-dom"


import app from "../../database/firebase"
import Main from '../MainScreen/main'
import Loading from '../../Views/Loading/loading'



function Check(){
    const [lodingView,setLoadingView]=useState(true);
    const [user,setUser]=useState(null);
    const navigate = useNavigate();
    checkUser(setLoadingView,setUser);

    return(
        <div>
            {lodingView ? <Loading/> : <Main user={user}/>}
        </div>
    );
}

function checkUser(setLoadingView,setUser){
    const auth = getAuth(app);
    auth.onAuthStateChanged((user) => {
        if (user) {
            const uid = user.uid;
            setUser(user);
            setLoadingView(false);
        } else {
            setLoadingView(false);
        }
    });
}

export default Check;
import React, { useState } from 'react';

function CircularProfileImage({
  letters,
  size = 50,
  backgroundColor = '#007bff',
  color = '#ffffff',
  fontFamily = 'Arial',
  borderColor = 'transparent',
  borderWidth = 0,
  hoverEffect = true
}) {
  const [isHovered, setIsHovered] = useState(false);

  const style = {
    width: `${size}px`,
    height: `${size}px`,
    borderRadius: '50%',
    backgroundColor: backgroundColor,
    color: color,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: `${size / 1.4}px`,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    fontFamily: fontFamily,
    border: `${borderWidth}px solid ${borderColor}`,
    transition: 'transform 0.3s ease',
    transform: isHovered && hoverEffect ? 'scale(1.1)' : 'scale(1)',
    cursor: hoverEffect ? 'pointer' : 'default'
  };

  const displayLetters = letters.split('').slice(0, 2).join('').toUpperCase();

  return (
    <div 
      style={style}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {displayLetters}
    </div>
  );
}

export default CircularProfileImage;
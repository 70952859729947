import React, { useState } from 'react';
import './mesagepopup.css'; // We'll create this CSS file separately

const Popup = ({ isOpen, onClose, text, imageUrl, linkUrl, linkText }) => {
  if (!isOpen) return null;
    
  return (
    <div className="popup-wrapper" onClick={onClose}>
      <div className="popup-content" onClick={(e) => e.stopPropagation()}>
        <button className="close-button" onClick={onClose}>&times;</button>
        {imageUrl && <img className="popup-image" src={imageUrl} alt="Popup image" />}
        <p className="popup-text">{text}</p>
        {linkUrl && (
          <a className="popup-link" href={linkUrl} target="_blank" rel="noopener noreferrer">
            {linkText || 'Learn More'}
          </a>
        )}
      </div>
    </div>
  );
};

const MessagePopup = ({message,isPopupopen , closePopup}) => {
  const [isPopupOpen, setIsPopupOpen] = useState(isPopupopen);

  return (
    <div>
      <Popup
        isOpen={isPopupopen}
        onClose={closePopup}
        text={message["message"]}
        imageUrl={message["image"]}
        linkUrl={message["link"]}
        linkText="Visit"
      />
    </div>
  );
};

export default MessagePopup;
import { useState, useRef, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import "../HomeScreen/Views/Home/home.css";
import { FaRupeeSign } from "react-icons/fa";
import ProfileView from "../HomeScreen/Views/Profile/profile";
import app from "../../database/firebase";
import { getAuth } from "firebase/auth";
import FileSpecView from "../HomeScreen/Views/FileItem/fileitem";
import { FaQrcode, FaHistory } from "react-icons/fa";
import Auth from "../../common/usercheck";

import ToolsMenu from "../HomeScreen/Views/Home/Toolsdropdown/toolsdropdown";

import {
  getStorage,
  ref as stref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import * as pdfjsLib from "pdfjs-dist";
import {
  getDatabase,
  ref,
  set,
  child,
  push,
  onValue,
  remove,
} from "firebase/database";
import LoadingView from "../../Views/Loading/loading";
import SelectPrinter from "../HomeScreen/Views/SelectPrinter/selectprinter";
import CircularProfileImage from "../../Views/CircularLetterImage/Circularimage";
import useAuthState from "../../common/usercheck";
function Printsdetails() {
  const { user, handleLogout, loading } = Auth();
  const [lodingView, setLoadingView] = useState(true);
  const [totalCost, setTotalCost] = useState(0);
  const [selectPrinter, setSelectPrinter] = useState(false);
  const menubtnRef = useRef(null);
  const navigate = useNavigate();
  const [selectedFiles, setSelectedFiles] = useState(null);
  function removefile(index) {
    setSelectedFiles(selectedFiles.filter((file, i) => i !== index));
  }
  const openRegistrationScreen = () => {
    if (user !== null) {
      setshowProfile(true);
    } else {
      navigate("/Autoprint/Registration");
    }
  };

  const [showProfile, setshowProfile] = useState(false);
  function showProfileview() {
    setshowProfile(!showProfile);
  }
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    id: "",
    image: "",
  });
  useEffect(() => {
    if (user != null) {
      getFilesIds(user, setSelectedFiles, setLoadingView, setTotalCost);
      getUserData(user, setUserData, setLoadingView);
    } else setLoadingView(false);
  }, [user]);
  const [printerProperties, setprinterProperties] = useState([]);
  useEffect(() => {
    const db = getDatabase(app);
    const dataRef = ref(db, "Printers/" + userData["printer"] + "/details"); // Replace 'your_database_path' with the path to your data in the Realtime Database

    onValue(dataRef, (snapshot) => {
      const fetchedData = snapshot.val();
      if (fetchedData) {
        const properties = fetchedData["properties"];
        var arr = [];
        for (let [key, value] of Object.entries(properties)) {
          arr.push({ name: key, values: value });
        }
        setprinterProperties(arr);
      } else {
        setprinterProperties([]);
      }
    });
  }, [userData]);
  return (
    <div className="App">
      <body>
        <div class="autoprint-toolbar">
          <div class="left">
            <h3
              onClick={() => navigate("/autoprint")}
              class="logo"
              style={{
                userSelect: "none",
                WebkitTouchCallout: "none",
                WebkitUserSelect: "none",
                msUserSelect: "none",
                cursor: "pointer",
              }}
            >
              <h2>AUTO</h2>Print
            </h3>
            {/* <ToolsMenu/> */}
          </div>
          <div class="right">
            <div class="app"></div>
            <button
              onClick={() => window.location.reload()}
              className="qr-scanner-btn"
            >
              <FaHistory size={24} /> {/* Adjust the size as needed */}
            </button>
            <div className="profile-icon">
              <RoundedImageButton
                userData={userData}
                isuser={user != null}
                menubtnRef={menubtnRef}
                src="https://icons.veryicon.com/png/o/internet--web/prejudice/user-128.png"
                onClick={
                  user == null ? openRegistrationScreen : showProfileview
                }
              />
              {showProfile && (
                <div class="profile-data">
                  <ProfileView
                    setIsOpen={setshowProfile}
                    menubtnRef={menubtnRef}
                    handleLogout={handleLogout}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div class="body">
          {selectedFiles != null && selectedFiles.length > 0 ? (
            <p>
              History :{" "}
              {selectedFiles.map((id, index) => (
                <div key={id}>
                  <FileView id={id} index={index} />
                  {index == selectedFiles.length - 1 ? (
                    <div class="bottom-space" />
                  ) : (
                    <div />
                  )}
                </div>
              ))}
            </p>
          ) : lodingView ? (
            <LoadingView />
          ) : (
            <div>No Prints!!!</div>
          )}
        </div>
      </body>
    </div>
  );
}

function RoundedImageButton(args) {
  const buttonStyle = {
    width: "32px",
    padding: "0px",
    height: "32px",
    borderRadius: "50%", // Make the button round
    overflow: "hidden", // Hide any content that exceeds the button's bounds
    border: "none", // Remove border
    cursor: "pointer", // Show pointer cursor on hover
  };

  const imageStyle = {
    padding: "0px",
    width: "30px", // Make the image fill the button's width
    height: "30px", // Make the image fill the button's height
    objectFit: "cover", // Ensure the image covers the entire button
  };

  return (
    <div class="menu">
      <button ref={args.menubtnRef} style={buttonStyle} onClick={args.onClick}>
        {args.isuser ? (
          args.userData["image"] != "" ? (
            <img
              src={args.userData["image"]}
              alt="Profile"
              style={imageStyle}
            />
          ) : (
            <CircularProfileImage
              letters={
                args.userData["name"][0] != null
                  ? args.userData["name"][0]
                  : ".."
              }
              size={32}
            />
          )
        ) : (
          <img src={args.src} alt="Button" style={imageStyle} />
        )}
      </button>
    </div>
  );
}

function FileView({ id, index }) {
  const [fileData, setFileData] = useState(null);
  const [lodingView, setLoadingView] = useState(true);
  const db = getDatabase(app);
  const dataRef = ref(
    db,
    "users/" + getAuth(app).currentUser.uid + "/printsdetails/" + id
  );
  useEffect(() => {
    // Replace 'your_database_path' with the path to your data in the Realtime Database
    onValue(dataRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const filedata = new PrintFileData(
          id,
          data["name"],
          data["count"],
          data["link"],
          data["pagecount"],
          data["progress"],
          data["properties"],
          data["cost"],
          data["status"],
          data["payment_id"],
          data["push_key"],
          data["printer"]
        );
        setFileData(filedata);
        setLoadingView(false);
      } else {
        setLoadingView(false);
      }
    });
  }, []);
  const removefile = () => {
    remove(dataRef);
  };

  if (fileData != null) {
    return (
      <div>
        {lodingView ? (
          <LoadingView />
        ) : (
          <div
            class="selectedfile"
            style={{
              backgroundImage: !fileData["status"]
                .toLowerCase()
                .includes("done")
                ? "linear-gradient(90deg, dimgray 25%, #a9a9a9 50%, dimgray 75%)"
                : "none",
              backgroundSize: !fileData["status"].toLowerCase().includes("done")
                ? "200% 100%"
                : "auto",
              animation: !fileData["status"].toLowerCase().includes("done")
                ? "loading 2s linear infinite"
                : "none",
            }}
          >
            {fileData["status"].toLowerCase().includes("done") ? (
              <div class="delete" onClick={removefile}>
                <img
                  src={require("./delete.png")}
                  width="25px"
                  height="25px"
                  alt=""
                  srcset=""
                />
              </div>
            ) : (
              <div></div>
            )}
            <h3>{fileData.name}</h3>
            <FileSpecView
              data={fileData}
              path="printsdetails"
              view={false}
            ></FileSpecView>
          </div>
        )}
      </div>
    );
  }
  return (
    <div>
      <LoadingView></LoadingView>
    </div>
  );
}

function getFilesIds(user, setSelectedFiles, setLoadingView, setTotalCost) {
  const db = getDatabase();
  const dbRef = ref(db, "users/" + user.uid + "/printsdetails");
  setLoadingView(true);
  onValue(dbRef, (snapshot) => {
    var ids = [];
    var totalCost = 0;
    snapshot.forEach((childSnapshot) => {
      const childKey = childSnapshot.key;
      const childData = childSnapshot.val();
      ids.push(childKey);
      totalCost += childData["cost"];
      // ...
    });
    setTotalCost(totalCost);
    setLoadingView(false);
    setSelectedFiles(ids.reverse());
  });
}

function getUserData(user, setUserData) {
  const db = getDatabase(app);
  const dataRef = ref(db, "users/" + user.uid + "/details"); // Replace 'your_database_path' with the path to your data in the Realtime Database

  onValue(dataRef, (snapshot) => {
    const fetchedData = snapshot.val();
    if (fetchedData) {
      setUserData({
        name: fetchedData["name"],
        email: fetchedData["email"],
        id: fetchedData.key,
        image: fetchedData["image"],
      }); // Convert object to array and set state
    } else {
    }
  });
}
class PrintFileData {
  constructor(
    id,
    name,
    count,
    link,
    pageCount,
    progress,
    properties,
    cost,
    status,
    payment_id,
    push_id,
    printer
  ) {
    this.id = id;
    this.name = name;
    this.count = count;
    this.link = link;
    this.pageCount = pageCount;
    this.progress = progress;
    this.properties = properties;
    this.cost = cost;
    this.status = status;
    this.payment_id = payment_id;
    this.push_id = push_id;
    this.printer = printer;
  }
}
export default Printsdetails;

import React from 'react';
import './main.css';
import Toolbar from './views/Toolbar/toolbar';
import Description from './views/Description/description';
import FeatureGrid from './views/Features/features';
import Footer from './views/Footer/footer';

function Main(args) {
  return (
    <div className="App">
      <Toolbar user={args} />
      <Description />
      <FeatureGrid />
      <Footer />
    </div>
  );
}
export default Main
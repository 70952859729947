
import React,{useState,useRef,useEffect} from 'react'
import { NavLink, useNavigate } from 'react-router-dom';
import { FaQrcode, FaSearch } from 'react-icons/fa';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import logo from '../../../../res/icons/atomicon.png';
import { getDatabase, ref, set ,child,push,onValue,remove} from "firebase/database";

import ProfileView from '../../../HomeScreen/Views/Profile/profile'

import app from "../../../../database/firebase"
import {getAuth} from 'firebase/auth'

import LoadingView from '../../../../Views/Loading/loading';
import { get } from 'firebase/database';
import CircularProfileImage from '../../../../Views/CircularLetterImage/Circularimage';

import Auth from '../../../../common/usercheck';
import MessagePopup from '../../../../Views/MessagePopup/messagePopup';

function Toolbar(args) {

  const [searchTerm, setSearchTerm] = useState('');
  const [items, setItems] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [isSearchFocused, setIsSearchFocused] = useState(false);

  useEffect(() => {
    const fetchItems = async () => {
      const db = getDatabase(app);
      const itemsRef = ref(db, 'Printers'); // Adjust 'items' to match your database structure
      const snapshot = await get(itemsRef);
      if (snapshot.exists()) {
        var itms=[]
        const itemsData = snapshot.val();
        for(const sn in itemsData) {
          itms.push({id:sn, ...itemsData[sn]["details"]})
        }
        setItems(itms);
      }
    };
  
    fetchItems();
  }, []);
  useEffect(() => {
    if (searchTerm) {
      console.log("Current items:", items);
      console.log("Search term:", searchTerm);
  
      const results = items.filter((item) => {
        console.log("Processing item:", item);
        // console.log("Processing key:", key, "item:", item);
  
        const idMatch = item.id && item.id.toLowerCase().includes(searchTerm.toLowerCase());
        const nameMatch = item.name && item.name.toLowerCase().includes(searchTerm.toLowerCase());
          const descriptionMatch = item.description && item.description.toLowerCase().includes(searchTerm.toLowerCase());
          return idMatch || nameMatch || descriptionMatch; // If item is not an object, only check the key
      });
  
      console.log("Search results:", results);
      setSearchResults(results);
    } else {
      setSearchResults([]);
    }
  }, [searchTerm, items]);
  const menubtnRef=useRef(null);
  const navigate = useNavigate();

  const [scanning, setScanning] = useState(false);
  const [result, setResult] = useState('');
  


  const [lodingView,setLoadingView]=useState(true);
  const [showProfile,setshowProfile]=useState(false);
  const { user, handleLogout ,loading} = Auth();
  function showProfileview(){
    setshowProfile(!showProfile);
}
  const openRegistrationScreen=()=>{
    if(user!=null){
        setshowProfile(true);
    }else{
        navigate("/Registration");
    }
}
    useEffect(()=>{
      setLoadingView(loading)
    },[loading])

    const [userData,setUserData] = useState({
      name:"",
      email:"",
      id:"",
      image:"",
    });

    const onsearchitemclick = (item) => {
      const db = getDatabase(app);
      const auth = getAuth(app);
    
      if (!auth.currentUser) {
        console.error("No user is currently signed in");
        // Maybe redirect to login page or show an error message
        navigate("/login");
        return;
      }
    
      const userUid = auth.currentUser.uid;
      
      if (!userUid) {
        console.error("User UID is undefined");
        return;
      }
    
      try {
        const printerRef = ref(db, `users/${userUid}/details/printer`);
        set(printerRef, item)
          .then(() => {
            console.log("Printer data saved successfully");
            navigate("/autoprint");
          })
          .catch((error) => {
            console.error("Error saving printer data:", error);
          });
        get(ref(getDatabase(app),"Printers/"+item+"/details/properties")).then((snapshot)=>{
          var properties=snapshot.val();
          var p={}
          for(let key of Object.keys(properties)){
            p[key]=properties[key][0]["name"];
          }

          get(ref(getDatabase(app),"users/"+getAuth(app).currentUser.uid+"/printstodo")).then((snapshot)=>{
            if(snapshot.val()){
              var files=Object.keys(snapshot.val());
                files.forEach((file)=>{
                  set(ref(getDatabase(app),"users/"+getAuth(app).currentUser.uid+"/printstodo/"+file+"/properties"),p)
              })

            }
            })
          
        })
      } catch (error) {
        console.error("Error in onsearchitemclick:", error);
      }
    };
    const openscanner=()=>{
      navigate("/qrscanner")
    }

    const [showUserMessagePopup, setShowUserMessagePopup] = useState(false);
    const [userMessage, setUserMessage] = useState({
      message: '',
      image:'',
      link:'',
    });
    useEffect(()=>{
      
      if(user!=null){
        
          getUserData(setUserData,setUserMessage,setShowUserMessagePopup);

      }
      else setLoadingView(false);
    },[user]);
    
    return (
      <header className="toolbar">
        <div className="logo" onClick={()=>{window.location.reload()}}>
          <span className="logo-text">Atomte</span>
          {/* <img src={logo} alt="Logo" className="logo" /> */}
        </div>
        
        <div className="search-container">
        <div className={`search-input-container ${isSearchFocused ? 'focused' : ''}`}>
          <FaSearch className="search-icon" />
          <input 
            type="text" 
            placeholder="Search..." 
            className="search-bar"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onFocus={() => setIsSearchFocused(true)}
            onBlur={() => setTimeout(() => setIsSearchFocused(false), 200)}
          />
        </div>
        {searchResults.length > 0 && isSearchFocused && (
            <div className="search-results">
              {searchResults.map((item, index) => {
                if (item) {
                  console.log(item, index);
                  return (
                    <div key={item.id || index} className="search-result-item" onClick={()=>{onsearchitemclick(item.id || index)}}>
                      <div className="search-result-content">
                        <div className="search-result-main">
                          <h3>ID: {item.id || 'N/A'}</h3>
                          <p>Name: {item.name || 'N/A'}</p>
                          {/* Add more fields as needed */}
                        </div>
                      </div>
                    </div>
                  );
                }
                return null; // Return null for any falsy items
              })}
            </div>
          )}
      </div>
      <div className='toolbar-right'>
      <button onClick={openscanner} className="qr-scanner-btn">
        <FaQrcode/>
        </button>
        <nav>
          <ul>
            <li><a href="/feedback">Feedback</a></li>
            <li><a href="#about">About</a></li>
            <li><a href="/careers">Careers</a></li>
          </ul>
        </nav>
        <div className="profile-icon">
        <RoundedImageButton userData={userData} isuser={user!=null} menubtnRef={menubtnRef} src="https://icons.veryicon.com/png/o/internet--web/prejudice/user-128.png" onClick={user==null ? openRegistrationScreen : showProfileview}/>
        {showProfile && <div class="profile-data"><ProfileView setIsOpen={setshowProfile} menubtnRef={menubtnRef} handleLogout={handleLogout}/></div>}
        </div>

      </div>
      {showUserMessagePopup?<div><MessagePopup message={userMessage} closePopup={()=>{setShowUserMessagePopup(false)}} isPopupopen={showUserMessagePopup}/></div>:<div/>}
      </header>
    );
  }
  function RoundedImageButton(args) {
    const buttonStyle = {
      width:"32px",
      padding:"0px",
      height:"32px",
      borderRadius: "50%", // Make the button round
      overflow: "hidden", // Hide any content that exceeds the button's bounds
      border: "none", // Remove border
      cursor: "pointer", // Show pointer cursor on hover
    };
  
    const imageStyle = {
      padding:"0px",
      width: "30px", // Make the image fill the button's width
      height: "30px", // Make the image fill the button's height
      objectFit: "cover", // Ensure the image covers the entire button
    };

    return (
      <div class="menu">
        <button ref={args.menubtnRef} style={buttonStyle} onClick={args.onClick}>
        {args.isuser?(args.userData["image"]!=""?
        <img src={args.userData["image"]} alt="Profile" style={imageStyle}  />:<CircularProfileImage letters={args.userData["name"][0]!=null?args.userData["name"][0]:".."} size={34}/>):
        <img src={args.src} alt="Button" style={imageStyle} />}
      </button>


      
      </div>
      
      
    );
}


function getUserData(setUserData,setUserMessage,setShowUserMessagePopup){
  
  const db = getDatabase(app);
    const dataRef = ref(db, 'users/'+getAuth(app).currentUser.uid+"/details"); // Replace 'your_database_path' with the path to your data in the Realtime Database

    onValue(dataRef, (snapshot) => {
      const fetchedData = snapshot.val();
      if (fetchedData) {
        setUserData({
          name:fetchedData["name"],
          email:fetchedData["email"],
          id:fetchedData.key,
          image:fetchedData["image"],
      }); // Convert object to array and set state
      
      } else {
       
      }
    });
    onValue(child(dataRef,"message"), (snapshot) => {
            const fetchedData = snapshot.val();
            const printermessage=fetchedData;
            if(printermessage){
                setUserMessage({message:printermessage["value"],image:printermessage["image"],link:printermessage["link"]});
                setShowUserMessagePopup(true);
            }
            
    });

}

  export default Toolbar;
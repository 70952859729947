import React, { useState } from 'react';

import app from '../../database/firebase';
import { getDatabase } from 'firebase/database';
import { ref, push, set } from 'firebase/database';
import { useNavigate } from 'react-router';
import { 
  Button, 
  TextField, 
  Radio, 
  RadioGroup, 
  FormControlLabel, 
  FormControl, 
  FormLabel, 
  Typography, 
  Box, 
  Rating,
  Snackbar
} from '@mui/material';

const FeedbackForm = () => {
  const [formData, setFormData] = useState({
    issues: '',
    issuesDetails: '',
    look: '',
    lookDetails: '',
    easeOfUse: 0,
    contentQuality: 0,
    loadingSpeed: 0,
    additionalComments: '',
  });
  const navigate = useNavigate();
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(null);
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    try {
      const feedbackRef = ref(getDatabase(app), 'feedback');
      const newFeedbackRef = push(feedbackRef);
      await set(newFeedbackRef, {
        ...formData,
        timestamp: Date.now()
      });
      setSubmitted(true);
      setFormData({
        issues: '',
        issuesDetails: '',
        look: '',
        lookDetails: '',
        easeOfUse: 0,
        contentQuality: 0,
        loadingSpeed: 0,
        additionalComments: '',
      });
      alert("Feedback submitted successfully!");
      navigate('/');
    } catch (error) {
        alert(error);
      console.error("Error submitting feedback: ", error);
      setError("Failed to submit feedback. Please try again.");
    }
  };

  const handleCloseSnackbar = () => {
    setSubmitted(false);
  };

  return (
    <Box sx={{ maxWidth: 600, margin: 'auto', padding: 3 }}>
      <Typography variant="h4" gutterBottom>
        Website Feedback
      </Typography>
      <form onSubmit={handleSubmit}>
        <FormControl component="fieldset" fullWidth margin="normal">
          <FormLabel>Are you facing any issues with this website?</FormLabel>
          <RadioGroup
            name="issues"
            value={formData.issues}
            onChange={handleChange}
          >
            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="no" control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>

        {formData.issues === 'yes' && (
          <TextField
            fullWidth
            margin="normal"
            name="issuesDetails"
            label="Please describe the issues (optional)"
            multiline
            rows={3}
            value={formData.issuesDetails}
            onChange={handleChange}
          />
        )}

        <FormControl component="fieldset" fullWidth margin="normal">
          <FormLabel>Does the website look good?</FormLabel>
          <RadioGroup
            name="look"
            value={formData.look}
            onChange={handleChange}
          >
            <FormControlLabel value="good" control={<Radio />} label="Good" />
            <FormControlLabel value="not" control={<Radio />} label="Not Good" />
          </RadioGroup>
        </FormControl>

        {formData.look === 'not' && (
          <TextField
            fullWidth
            margin="normal"
            name="lookDetails"
            label="Please describe what could be improved (optional)"
            multiline
            rows={3}
            value={formData.lookDetails}
            onChange={handleChange}
          />
        )}

        <Box sx={{ marginTop: 2 }}>
          <Typography component="legend">How easy is the website to use?</Typography>
          <Rating
            name="easeOfUse"
            value={formData.easeOfUse}
            onChange={(event, newValue) => {
              setFormData(prevState => ({...prevState, easeOfUse: newValue}));
            }}
          />
        </Box>

        <Box sx={{ marginTop: 2 }}>
          <Typography component="legend">How would you rate the quality of content?</Typography>
          <Rating
            name="contentQuality"
            value={formData.contentQuality}
            onChange={(event, newValue) => {
              setFormData(prevState => ({...prevState, contentQuality: newValue}));
            }}
          />
        </Box>

        <Box sx={{ marginTop: 2 }}>
          <Typography component="legend">How satisfied are you with the website's loading speed?</Typography>
          <Rating
            name="loadingSpeed"
            value={formData.loadingSpeed}
            onChange={(event, newValue) => {
              setFormData(prevState => ({...prevState, loadingSpeed: newValue}));
            }}
          />
        </Box>

        <TextField
          fullWidth
          margin="normal"
          name="additionalComments"
          label="Any additional comments or suggestions?"
          multiline
          rows={4}
          value={formData.additionalComments}
          onChange={handleChange}
        />

        <Button type="submit" variant="contained" color="primary" sx={{ marginTop: 2 }}>
          Submit Feedback
        </Button>
      </form>

      <Snackbar
        open={submitted}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message="Thank you for your feedback!"
      />
    </Box>
  );
};

export default FeedbackForm;
import React from 'react';
import styled, { keyframes } from 'styled-components';

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width:100%;
  height: 100vh;
  background-color: #ffffff; /* Black background */
`;

const Spinner = styled.div`
  width: 40px;
  height: 40px;
  border: 6px solid rgba(0, 0, 0, 0.6);
  border-top-color: #ffffff;
  border-radius: 50%;
  animation: ${spin} 1s linear infinite;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2); /* Subtle shadow */
`;

const LoadingText = styled.div`
  margin-top: 20px;
  font-size: 1.3rem;
  color: rgba(0, 0, 0, 0.6);
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3); /* Subtle text shadow */
`;

const LoadingView = () => (
  <LoadingContainer>
    <Spinner />
    <LoadingText>Loading...</LoadingText>
  </LoadingContainer>
);

export default LoadingView;

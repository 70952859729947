import "../RegistrationForm/registrationform.css"
import React from 'react';
import {useState} from 'react'
import { Link,useNavigate } from 'react-router-dom';

import { GoogleLoginButton } from 'react-social-login-buttons';
import app from '../../../../database/firebase.js'
import { getAuth,signInWithPopup,createUserWithEmailAndPassword , GoogleAuthProvider,getAdditionalUserInfo, signInAnonymously} from "firebase/auth";
import { getDatabase, ref, set } from "firebase/database";
import Loading from '../../../../Views/Loading/loading'

function AnLogin(args) {
    var nav=useNavigate();
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({
      prompt: 'select_account'
    });
    const signIn=() => {
        if(formData.name==""){
          alert("Please enter name");
          return;
        }
        setLoadingView(true);
    
        const auth = getAuth(app);
        signInAnonymously(auth)
          .then((userCredential) => {
            const user = userCredential.user;
            const db = getDatabase();
            set(ref(db, 'users/' + user.uid+"/details"), {
              name: formData.name,
              email: "",
              image : ""
            }).then(()=>{
              nav(args.backpath);
              setLoadingView(false);
            }).catch((error) => {
    
            });
            
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorMessage);
            setLoadingView(false);
            alert(errorMessage);
            // ..
          });
      };
      const handleChange = (e) => {
        e.preventDefault();
        setFormData({...formData, [e.target.name]: e.target.value });
      };
    const [googleloginloading,setGoogleLoginLoading]=useState(false);
    function logintWithGoogle(){
        setGoogleLoginLoading(true)
        const auth = getAuth(app);
        signInWithPopup(auth, provider)
        .then((result) => {
            // This gives you a Google Access Token. You can use it to access the Google API.
            const credential = GoogleAuthProvider.credentialFromResult(result);
            const token = credential.accessToken;
            // The signed-in user info.
            const user = result.user;
            const db = getDatabase();
            const data=getAdditionalUserInfo(result);
            if(data["isNewUser"]){
              set(ref(db, 'users/' + user.uid+"/details"), {
                name: data["profile"]["given_name"]+data["profile"]["family_name"],
                email: data["profile"]["email"],
                image : data["profile"]["picture"]
              }).then(()=>{
                nav(args.backpath);
              setGoogleLoginLoading(false)
              setLoadingView(false);
                
              }).catch((error) => {
                setLoadingView(false);
                setGoogleLoginLoading(false)
              });
            }else{
              nav(args.backpath);
              setLoadingView(false);
              setGoogleLoginLoading(false)
            }
            
        }).catch((error) => {
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
            // The email of the user's account used.
            const email = error.customData.email;
            setGoogleLoginLoading(false)
            // The AuthCredential type that was used.
            const credential = GoogleAuthProvider.credentialFromError(error);
            // ...
        });
      }
    const [lodingView,setLoadingView]=useState(false);
    const [formData, setFormData] = useState({
        name: ''
    });
    return (
        <div>
          {lodingView ? <Loading/> : 
          <div className="registration-container">
            <div className="registration-form">
            <h2>Continue without Account</h2>
            <div>
              <div className="form-group">
                <label htmlFor="name"><i className="fas fa-user"></i> Name</label>
                <input type="text" id="name" name="name" value={formData.name} onChange={handleChange}/>
              </div>
              <div>
                <button onClick={signIn} className="btn-signup"><i className="fas fa-user-plus"></i> Continue</button>
              </div>
            </div>
            <p>---or---</p>
            <GoogleLoginButton
                className="btn-google"
                onClick={logintWithGoogle}
                disabled={googleloginloading} // Disable button if loading
              >
                {googleloginloading ? (
                  <>
                  <div className='google-btn-loading'>
                      <span className="spinner"></span> Loading...
                  </div>
                    
                  </>
                ) : (
                  "Login with Google"
                )}
              </GoogleLoginButton>
            <p className="login-link">Already a member? <Link to={args.backpath+"login"}>Login</Link></p>
            <p>---or---</p>
            <p className="login-link">Need an Account? <Link to={args.backpath+"registration"}>Signin</Link></p>
          </div>
        </div>}
        </div>
      );
}

export default AnLogin

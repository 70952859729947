import './App.css';

import {getAuth} from "firebase/auth"


import app from "./database/firebase"

import RegistrationScreen from './Screens/RegistrationScreen/main'

import CheckScreen from './Screens/UsercheckScreen/usercheck'
import AutoprintHomeScreen from './Screens/HomeScreen/main'
import PrintsPreviewScreen from './Screens/PrintsPreviewScreen/main'
import LoginScreen from './Screens/RegistrationScreen/Views/LoginForm/login';
import {Routes,BrowserRouter as Router,Route} from "react-router-dom";
import Printsdetails from './Screens/PrintsDetailsScreen/printsdetails';

import AnLogin from './Screens/RegistrationScreen/Views/AnLogin/anlogin';
import QRScanner from './Views/QrScanner/scanner';

import React, { useState, useEffect } from 'react';
import FeedbackForm from './Screens/Feedback/feedback';
import CareerOptions from './Screens/CareerOptions/careeroptions';
import UpcomingFeature from './Views/UpcommingScreen/upcomming';
function App() {
  const [user,setUser]=useState(null);
  useEffect(() => {
    checkUser(setUser);
  }, []);
  return (
    <Router>
      <div>
        <section>
          <Routes>
            <Route path="/" element={<CheckScreen/>} />
            <Route path="/Registration" element={<RegistrationScreen backpath={'/'}/>} />
            <Route path="/Login" element={<LoginScreen backpath={'/'}/>} />
            <Route path="/Autoprint" element={<AutoprintHomeScreen/>} />
            <Route path="/Autoprint/Registration" element={<RegistrationScreen backpath={'/Autoprint/'}/>} />
            <Route path="/Autoprint/PrintsPreview" element={<PrintsPreviewScreen/>} />
            <Route path="/Autoprint/Login" element={<LoginScreen backpath={'/Autoprint/'}/>} />
            <Route path="/Autoprint/history" element={<Printsdetails user={user}/>} />
            <Route path="/Autoprint/AnLogin" element={<AnLogin backpath={'/Autoprint/'}/>} />
            <Route path="/AnLogin" element={<AnLogin backpath={'/'}/>} />
            <Route path='/qrscanner' element={<QRScanner/>}/>
            <Route path='/feedback' element={<FeedbackForm/>}/>
            <Route path='/careers' element={<CareerOptions/>}/>
            <Route path='/upcomming' element={<UpcomingFeature/>}/>
          </Routes>

        </section>
      </div>
      
    </Router>

  );
}

function checkUser(setUser){
  const auth = getAuth(app);
  auth.onAuthStateChanged((user) => {
      if (user) {
          const uid = user.uid;
          setUser(user);
          
      } else {
          
      }
  });
}

export default App;

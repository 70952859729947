import React, { useRef, useEffect, useState } from 'react';
import QrScanner from 'qr-scanner';
import { useNavigate } from 'react-router';
import Auth from '../../common/usercheck';
import { getDatabase, ref, set,get } from "firebase/database";
import { getAuth } from "firebase/auth";
import app from "../../database/firebase"
function WebcamQRScanner() {
    var nav=useNavigate();
  const videoRef = useRef(null);
  const [qrScanner, setQrScanner] = useState(null);
    const { user, handleLogout ,loading} = Auth();
  useEffect(() => {
    if (videoRef.current) {
      const scanner = new QrScanner(
        videoRef.current,
        result => {
          result=result["data"]
          result=result.split("-");
          
            if(result[0]=="atomte" && result.length==3){
                if(result[1]=="autoprint"){
                    if(getAuth(app).currentUser!=null){
                        set(ref(getDatabase(app),"users/"+getAuth(app).currentUser.uid+"/details/printer"),result[2])
                        get(ref(getDatabase(app),"Printers/"+result[2]+"/details/properties")).then((snapshot)=>{
                          var properties=snapshot.val();
                          var p={}
                          for(let key of Object.keys(properties)){
                            p[key]=properties[key][0]["name"];
                          }
                          get(ref(getDatabase(app),"users/"+getAuth(app).currentUser.uid+"/printstodo")).then((snapshot)=>{
                            if(snapshot.val()){
                              var files=Object.keys(snapshot.val());
                                files.forEach((file)=>{
                                  set(ref(getDatabase(app),"users/"+getAuth(app).currentUser.uid+"/printstodo/"+file+"/properties"),p)
                              })
                              
                            }
                            nav('/Autoprint')
                            })
                        })
                    }else{
                      nav('/Autoprint/Registration')
                    }
                    
                }
            }
        },
        { 
          returnDetailedScanResult: true,
          // You can add more options here as needed
        }
      );

      scanner.start();
      setQrScanner(scanner);

      return () => {
        scanner.stop();
      };
    }
  }, [user]);

  const handleClose = () => {
    if (qrScanner) {
      qrScanner.stop();
    }
    
  };

  return (
    <div className="qr-scanner-overlay">
      <video ref={videoRef}></video>
    </div>
  );
}

export default WebcamQRScanner;
import React from 'react';
import {useState} from 'react'
import { Link,useNavigate } from 'react-router-dom';

import { GoogleLoginButton } from 'react-social-login-buttons';
import app from '../../../../database/firebase.js'
import { getAuth,signInWithPopup,createUserWithEmailAndPassword , GoogleAuthProvider,getAdditionalUserInfo, signInWithEmailAndPassword} from "firebase/auth";
import { getDatabase, ref, set } from "firebase/database";
import './registrationform.css'; // Import CSS file for styling
import Loading from '../../../../Views/Loading/loading'



function RegistrationPage(args) {
  const nav=useNavigate();
  
  const [lodingView,setLoadingView]=useState(false);
  const [googleloginloading,setGoogleLoginLoading]=useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: ''
  });
  
  const provider = new GoogleAuthProvider();
  provider.setCustomParameters({
    prompt: 'select_account'
  });
  function logintWithGoogle(){
//    setLoadingView(true)
    setGoogleLoginLoading(true)
    const auth = getAuth(app);
    signInWithPopup(auth, provider)
    .then((result) => {
  //      setLoadingView(true)
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential ? credential.accessToken : null;
        const user = result.user;
        const db = getDatabase();
        const data = getAdditionalUserInfo(result);
        if (data && data.isNewUser) {

            const profile = data.profile || {};
            const name = (profile.given_name || '') + (profile.family_name || '');
            const email = profile.email || '';
            const image = profile.picture || '';

            set(ref(db, 'users/' + user.uid + "/details"), {
                name: name,
                email: email,
                image: image
            }).then(() => {
                nav(args.backpath);
                setGoogleLoginLoading(false)
                setLoadingView(false);
            }).catch((error) => {
                console.error("Error updating user details:", error);
                setLoadingView(false);
                setGoogleLoginLoading(false)
            });
        } else {
            nav(args.backpath);
            setLoadingView(false);
            setGoogleLoginLoading(false)
        }
        
    }).catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        alert(errorMessage)
        setLoadingView(false)
        setGoogleLoginLoading(false)
        // ...
    });
  }


  const signIn=() => {
    if(formData.email=="" || formData.password=="" || formData.name==""){
      alert("Please enter all details");
      return;
    }else if(formData.password.length<6){
      alert("Password must be 6 characters long");
      return;
    }
    setLoadingView(true);

    const auth = getAuth(app);
    createUserWithEmailAndPassword(auth, formData.email, formData.password)
      .then((userCredential) => {
        const user = userCredential.user;
        console.log(user);
        const db = getDatabase();
        set(ref(db, 'users/' + user.uid+"/details"), {
          name: formData.name,
          email: formData.email,
          image: ""
        }).then(()=>{
          nav(args.backpath);
          setLoadingView(false);
        }).catch((error) => {
          alert(error);
        });
        
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        alert(errorMessage);
        setLoadingView(false);
        // ..
      });
  };
  const handleChange = (e) => {
    e.preventDefault();
    setFormData({...formData, [e.target.name]: e.target.value });
  };
  const goto=()=>{
    nav(args.backpath+"anlogin");
  }
  return (
    <div>
      {lodingView ? <Loading/> : 
      <div className="registration-container">
        <div className="registration-form">
        <h2>Register</h2>
        <div>
          <div className="form-group">
            <label htmlFor="name"><i className="fas fa-user"></i> Name</label>
            <input type="text" id="name" name="name" value={formData.name} onChange={handleChange}/>
          </div>
          <div className="form-group">
            <label htmlFor="email"><i className="fas fa-envelope"></i> Email</label>
            <input type="email" id="email" name="email" value={formData.email} onChange={handleChange}/>
          </div>
          <div className="form-group">
            <label htmlFor="password"><i className="fas fa-lock"></i> Password</label>
            <input type="password" id="password" name="password" value={formData.password} onChange={handleChange}/>
          </div>
          <div>
            <button onClick={signIn} className="btn-signup"><i className="fas fa-user-plus"></i> Sign Up</button>
          </div>
        </div>
        <p>---or---</p>
         <GoogleLoginButton
      className="btn-google"
      onClick={logintWithGoogle}
      disabled={googleloginloading} // Disable button if loading
    >
      {googleloginloading ? (
        <>
          <div className='google-btn-loading'>
             <span className="spinner"></span> Loading...
          </div>
        </>
      ) : (
        "Login with Google"
      )}
    </GoogleLoginButton>
        <p className="login-link">Already a member? <Link to={args.backpath+"login"}>Login</Link></p>
        <p>---or---</p>
        <div className='cont-btn' onClick={goto}>
        <button className="continue-button">
        Continue without account
        <span className="continue-button-arrow">→</span>
      </button>
      </div>
      </div>
    </div>}
    </div>
  );
}


export default RegistrationPage;



import React, { useState, useEffect } from 'react';
import { getAuth, signInAnonymously, onAuthStateChanged, signOut, deleteUser } from "firebase/auth";
import { getDatabase, ref, set,remove } from "firebase/database";
import app from "../database/firebase";
import { useNavigate } from 'react-router';

function Auth() {
const [user, setUser] = useState(null);
const [loading, setLoading] = useState(true);
var navigate=useNavigate();
useEffect(() => {
    
    const auth = getAuth(app);
    
    const signInAnonymouslyIfNeeded = async () => {
    try {
        if (!auth.currentUser) {
        await signInAnonymously(auth);
        }
    } catch (error) {
        alert("Error signing in anonymously:", error);
    }
    };



    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
        if (currentUser) {
            setUser(currentUser);
            
        } else {
            // navigate("/Autoprint/Registration");
            // signInAnonymouslyIfNeeded();
        }
    setLoading(false);
    });

    return () => unsubscribe();
}, []);

const handleLogout = async () => {
    setLoading(true);
    const auth = getAuth(app);
    try {
    if (auth.currentUser && auth.currentUser.isAnonymous) {
        const db = getDatabase(app);
        remove(ref(db, 'users/'+getAuth(app).currentUser.uid));
        await deleteUser(auth.currentUser);
    }
    await signOut(auth);
    window.location.reload(true);
    } catch (error) {
    alert("Error during logout:", error);
    }
};

return {user,handleLogout,loading};
}

export default Auth;
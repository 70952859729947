import { useState, useRef, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import "./home.css";
import { FaRupeeSign } from "react-icons/fa";
import ProfileView from "../Profile/profile";
import app from "../../../../database/firebase";
import { getAuth } from "firebase/auth";
import FileSpecView from "../FileItem/fileitem";
import { FaQrcode } from "react-icons/fa";
import {
  getStorage,
  ref as stref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import * as pdfjsLib from 'pdfjs-dist/webpack.mjs';
import {
  getDatabase,
  ref,
  set,get,
  child,
  push,
  onValue,
  remove,
} from "firebase/database";
import LoadingView from "../../../../Views/Loading/loading";
import SelectPrinter from "../SelectPrinter/selectprinter";
import { FaHistory } from "react-icons/fa"; // FontAwesome History Icon
import { MdHistory } from "react-icons/md";
import ToolsMenu from "./Toolsdropdown/toolsdropdown";

import CircularProfileImage from "../../../../Views/CircularLetterImage/Circularimage";
import styled, { keyframes } from "styled-components";

import Auth from "../../../../common/usercheck";
import MessagePopup from "../../../../Views/MessagePopup/messagePopup";

function Home() {
  const navigate = useNavigate();
  useEffect(() => {
    if(getAuth(app).currentUser!=null){
    const db = getDatabase();
    const dbRef = ref(
      db,
      "users/" + getAuth(app).currentUser.uid + "/printstodo"
    );
    setLoadingView(true);
    get(dbRef).then((snapshot) => {
      var nav=0
      snapshot.forEach((childSnapshot) => {
        const childData = childSnapshot.val();
        const childKey = childSnapshot.key;
        if(childData.order_id!=null){
          var s=checkOrderPaymentStatus(childData.order_id,child(dbRef,childKey));
          if(s==1){
            nav=1
          }
        }
      })
      if(nav==1){
          navigate("/autoprint/history")
      }
    })
  }
  },[])
  const [lodingView, setLoadingView] = useState(true);
  const [totalCost, setTotalCost] = useState(0);
  const [progress, setProgress] = useState(0);
  const [selectPrinter, setSelectPrinter] = useState(false);

  const [totalpages, setTotalpages] = useState(0);
  const menubtnRef = useRef(null);
  const { user, handleLogout, loading } = Auth();
  const [selectedFiles, setSelectedFiles] = useState(null);
  const [selectedUploadFiles, setSelectedUploadFiles] = useState(null);
  const openRegistrationScreen = () => {
    if (user !== null) {
      setshowProfile(true);
    } else {
      navigate("/Autoprint/Registration");
    }
  };
  const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;
  const messageText = styled.div`
    font-size: 15px;
    margin-top: 5px;
    color: red;
    display: flex;
    animation: ${fadeIn} 1s ease-in-out infinite alternate;
  `;
  const [showProfile, setshowProfile] = useState(false);
  function showProfileview() {
    setshowProfile(!showProfile);
  }
  const [proceed, setProceed] = useState(false);
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    id: "",
    image: "",
    printer: "No Printer Selected",
    printer_message: "",
  });
  useEffect(() => {
    setLoadingView(loading);
  }, [loading]);
  const openscanner = () => {
    navigate("/qrscanner");
  };
  useEffect(() => {
    if (user != null) {
      getFilesIds(
        setSelectedFiles,
        setSelectedUploadFiles,
        setLoadingView,
        setTotalCost,
        setTotalpages,
        setProgress
      );
      getUserData(setUserData, setLoadingView);
    } else {
      setLoadingView(false);
    }
  }, [user]);
  const [printer, setPrinter] = useState({
    id: "",
    name: "",
    printer_message: "",
    status: false,
    pageleft:0,
  });
  const [queue, setQueue] = useState(0);
  const [printerProperties, setprinterProperties] = useState([]);
  const [showPrinterMessagePopup, setShowPrinterMessagePopup] = useState(false);
  const [printerMessage, setPrinterMessage] = useState({
    message: "",
    image: "",
    link: "",
  });
  useEffect(() => {
    const db = getDatabase(app);
    const dataRef = ref(db, "Printers/" + userData["printer"] + "/details"); // Replace 'your_database_path' with the path to your data in the Realtime Database

    onValue(dataRef, (snapshot) => {
      const fetchedData = snapshot.val();
      if (fetchedData) {
        const properties = fetchedData["properties"];
        var arr = [];
        for (let [key, value] of Object.entries(properties)) {
          arr.push({ name: key, values: value });
        }
        setprinterProperties(arr);

        setPrinter({
          id: userData["printer"],
          name: fetchedData["name"],
          printer_message: fetchedData["status_message"],
          status: fetchedData["status"] == "Active" ? true : false,
          pageleft: fetchedData["pagecount"]-fetchedData["printed"],
        });
      } else {
        setPrinter({
          id: userData["printer"],
          name: "Select Printer",
          printer_message: "",
          status: false,
          pageleft: 0,
        });
        setprinterProperties([]);
      }
    });
    if (userData["printer"] != "No Printer Selected") {
      const dataRef = ref(db, "Printers/" + userData["printer"] + "/printstodo");
      onValue(dataRef, (snapshot) => {
        const fetchedData = snapshot.val();
        if(fetchedData){
            if (Object.keys(fetchedData).length> 0 && fetchedData) {
                setQueue(Object.keys(fetchedData).length);
              }
        }else{
          setQueue(0);
        }
      })
      onValue(child(dataRef, "message"), (snapshot) => {
        const fetchedData = snapshot.val();
        const printermessage = fetchedData;
        if (printermessage) {
          setPrinterMessage({
            message: printermessage["value"],
            image: printermessage["image"],
            link: printermessage["link"],
          });
          setShowPrinterMessagePopup(true);
        }
      });
    }
  }, [userData]);
  const onSelectprinterclick = () => {
    setProceed(false);
    setSelectPrinter(true);
  };
  const onfilePicker = (sf) => {
    if (sf != "noprinter") {
      uploadFiles(sf, printerProperties, totalCost, setTotalCost);
    } else {
      setProceed(false);
      setSelectPrinter(true);
    }
  };

  return (
    <div className="App">
      <body>
        <div class="autoprint-toolbar">
          <div class="left">
            <h3
              onClick={() => window.location.reload()}
              class="logo"
              style={{
                userSelect: "none",
                WebkitTouchCallout: "none",
                WebkitUserSelect: "none",
                msUserSelect: "none",
                cursor: "pointer",
              }}
            >
              <h2>AUTO</h2>Print
            </h3>
            {/* <ToolsMenu/> */}
          </div>
          <div class="right">
            <div class="app"></div>

            <button
              onClick={
                user != null
                  ? () => navigate("/autoprint/history")
                  : () => navigate("/autoprint/registration")
              }
              className="qr-scanner-btn"
            >
              <FaHistory size={24} /> {/* Adjust the size as needed */}
            </button>

            <button onClick={openscanner} className="qr-scanner-btn">
              <FaQrcode />
            </button>
            <div className="profile-icon">
              <RoundedImageButton
                userData={userData}
                isuser={user != null}
                menubtnRef={menubtnRef}
                src="https://icons.veryicon.com/png/o/internet--web/prejudice/user-128.png"
                onClick={
                  user == null ? openRegistrationScreen : showProfileview
                }
              />
              {showProfile && (
                <div class="profile-data">
                  <ProfileView
                    setIsOpen={setshowProfile}
                    menubtnRef={menubtnRef}
                    handleLogout={handleLogout}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div class="body">
          <h1>PRINT with PRIVACY</h1>
          <h4>
            You can print any files with privacy and with comfort.Try it once...
          </h4>
          <FilePicker
            onChange={(sf) => {
              onfilePicker(sf);
            }}
            user={user}
            navigate={navigate}
            printer={printer["name"] == "Select Printer" ? null : printer}
          />

          <h4>or drop the files here</h4>
          {/* <button className="printer-button" onClick={user==null?()=>navigate("/autoprint/registration"):onSelectprinterclick}>
                    <div className={`active-indicator ${(printer.status=="Active") ? 'active' : 'inactive'}`}></div><span>{printer.name+" : "}</span><br/><span>{printer.printer_message}</span><i className="fas fa-chevron-down"></i>
                            </button>   */}

          <div>{queue} {queue==1?"member":"members"} {queue==1?"is":"are"} waiting</div>
          <button
            className="printer-button"
            onClick={
              user == null
                ? () => navigate("/autoprint/registration")
                : onSelectprinterclick
            }
          >
            <div
              className={`active-indicator ${
                printer.status ? "active" : "inactive"
              }`}
            ></div>
            <div className="content">
              <div className="printer-name">{printer.name}</div>
              <div className="printer-message">{printer.printer_message}</div>
            </div>

            <i className="fas fa-chevron-down"></i>
          </button>
          <p>{"Papers Left : " + printer.pageleft}</p>
          <br />
          {(selectedFiles != null && selectedFiles.length > 0) ||
          (selectedUploadFiles != null && selectedUploadFiles.length > 0) ? (
            <p>
              Selected files:{" "}
              {[...selectedUploadFiles, ...selectedFiles].map((id, index) => (
                <div key={id}>
                  <FileView
                    id={id}
                    index={index}
                    pProperties={printerProperties}
                  />
                  {index ==
                  selectedFiles.length + selectedUploadFiles.length - 1 ? (
                    <div class="bottom-space" />
                  ) : (
                    <div />
                  )}
                </div>
              ))}
            </p>
          ) : lodingView ? (
            <LoadingView />
          ) : (
            <div>No Items</div>
          )}
        </div>
        {selectedFiles != null ? (
          selectedFiles.length > 0 ? (
            <div className="footer">
              {totalCost > 0 ? (
                <div
                  className={`printbtn ${
                    progress % 100 !== 0 ? "disabled" : ""
                  }`}
                  onClick={() => {
                     if(printer['pageleft']-totalpages<=10){
                      alert("Not enough papers left")
                      return
                    }
                     if (selectedUploadFiles.length > 0) {
                      const dbRef = ref(
                        getDatabase(app),
                        "users/" + getAuth(app).currentUser.uid + "/printstodo"
                      );
                      selectedUploadFiles.forEach((id) => {
                        remove((dbRef, id));
                      });
                    }
                    if (progress % 100 === 0) {
                      setProceed(true);
                      setSelectPrinter(true);
                    }
                  }}
                >
                  {progress % 100 !== 0 ? (
                    <div className="loading-spinner"></div>
                  ) : null}
                  <h3>
                    Print {selectedFiles.length}{" "}
                    {selectedFiles.length > 1 ? "Files" : "File"}
                  </h3>

                  <FaRupeeSign />
                  <p>{totalCost}</p>
                </div>
              ) : (
                <></>
              )}
            </div>
          ) : (
            <div />
          )
        ) : (
          <div />
        )}
        {selectPrinter ? (
          <div>
            <SelectPrinter
              printer={printer}
              proceed={proceed}
              printerid={printer["name"]}
              totalCost={totalCost}
              onItemSelect={(it) => {
                setSelectPrinter(false);
              }}
              onClose={() => {
                setSelectPrinter(false);
              }}
            ></SelectPrinter>
          </div>
        ) : (
          <div />
        )}
        {showPrinterMessagePopup ? (
          <div>
            <MessagePopup
              message={printerMessage}
              closePopup={() => {
                setShowPrinterMessagePopup(false);
              }}
              text={printerMessage["message"]}
              imageUrl={printerMessage["image"]}
              linkUrl={printerMessage["link"]}
              linkText={"link"}
              isPopupopen={showPrinterMessagePopup}
            />
          </div>
        ) : (
          <div />
        )}
      </body>
    </div>
  );
}

function RoundedImageButton(args) {
  const buttonStyle = {
    width: "32px",
    padding: "0px",
    height: "32px",
    borderRadius: "50%", // Make the button round
    overflow: "hidden", // Hide any content that exceeds the button's bounds
    border: "none", // Remove border
    cursor: "pointer", // Show pointer cursor on hover
  };

  const imageStyle = {
    padding: "0px",
    width: "30px", // Make the image fill the button's width
    height: "30px", // Make the image fill the button's height
    objectFit: "cover", // Ensure the image covers the entire button
  };
  return (
    <div class="menu">
      <button ref={args.menubtnRef} style={buttonStyle} onClick={args.onClick}>
        {args.isuser ? (
          args.userData["image"] != "" ? (
            <img
              src={args.userData["image"]}
              alt="Profile"
              style={imageStyle}
            />
          ) : (
            <CircularProfileImage
              letters={
                args.userData["name"][0] != null
                  ? args.userData["name"][0]
                  : ".."
              }
              size={32}
            />
          )
        ) : (
          <img src={args.src} alt="Button" style={imageStyle} />
        )}
      </button>
    </div>
  );
}

function FilePicker({ onChange, user, navigate, printer = null }) {
  const inpref = useRef(null);
  const handleClick = () => {
    inpref.current.click();
  };
  const handleFilechange = (event) => {
    const sf = Array.from(event.target.files);
    const allowedTypes = ['application/pdf', 'image/png', 'image/jpeg', 'image/jpg'];
    const invalidFiles = sf.filter(file => !allowedTypes.includes(file.type));
    const validFiles = sf.filter(file => allowedTypes.includes(file.type));

    if (invalidFiles.length > 0) {
      alert('Please select only PDF, PNG, or JPG files.');// Reset the input
    }
      onChange(validFiles);
      event.target.value = null; 
  };

  const handleFilechange1 = async (event) => {
    const sf = Array.from(event.target.files);
    const allowedTypes = ['application/pdf', 'image/png', 'image/jpeg', 'image/jpg'];
    const invalidFiles = sf.filter(file => !allowedTypes.includes(file.type));
    const validFiles = [];
  
    for (const file of sf) {
      if (allowedTypes.includes(file.type)) {
        if (file.type === 'application/pdf') {
          try {
            const arrayBuffer = await file.arrayBuffer();
            await pdfjsLib.getDocument({ data: arrayBuffer }).promise;
            validFiles.push(file);
          } catch (error) {
            if (error.name === 'PasswordException') {
              alert(`The PDF file "${file.name}" is password-protected and cannot be uploaded.`);
            } else {
              console.error('Error reading PDF:', error);
              alert(`Error reading the PDF file "${file.name}". Please try again.`);
            }
          }
        } else {
          validFiles.push(file);
        }
      }
    }
  
    if (invalidFiles.length > 0) {
      alert('Please select only PDF, PNG, or JPG files.');
    }
  
    if (validFiles.length > 0) {
      onChange(validFiles);
    }
  
    event.target.value = null;
  };


  return (
    <div>
      <div
        class="selectbtn"
        onClick={
          user == null
            ? () => navigate("/autoprint/registration")
            : printer
            ? handleClick
            : () => onChange("noprinter")
        }
      >
        <h1>Select Files</h1>
        <input
          type="file"
          multiple
          onChange={handleFilechange}
          style={{ display: "none" }}
          ref={inpref}
          accept="application/pdf,image/png,image/jpeg,image/jpg"
        />
      </div>
    </div>
  );
}

function FileView({ id, index, pProperties }) {
  const [fileData, setFileData] = useState(null);
  const [lodingView, setLoadingView] = useState(true);
  const [printerProperties, setprinterProperties] = useState([]);
  useEffect(() => {
    setprinterProperties(pProperties);
  }, [pProperties]);
  const db = getDatabase(app);
  const dataRef = ref(
    db,
    "users/" + getAuth(app).currentUser.uid + "/printstodo/" + id
  );
  useEffect(() => {
    // Replace 'your_database_path' with the path to your data in the Realtime Database

    onValue(dataRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const filedata = new PrintFileData(
          id,
          data["name"],
          data["count"],
          data["link"],
          data["pagecount"],
          data["progress"],
          data["properties"],
          data["cost"]
        );
        setFileData(filedata);
        setLoadingView(false);
      } else {
        setLoadingView(false);
      }
    });
  }, []);
  const removefile = () => {
    remove(dataRef);
  };

  if (fileData != null && printerProperties.length > 0) {
    return (
      <div>
        {lodingView ? (
          <LoadingView />
        ) : (
          <div
            class="selectedfile"
            style={{
              backgroundImage:
                fileData.progress != 100
                  ? "linear-gradient(90deg, dimgray 25%, #a9a9a9 50%, dimgray 75%)"
                  : "none",
              backgroundSize: fileData.progress != 100 ? "200% 100%" : "auto",
              animation:
                fileData.progress != 100
                  ? "loading 2s linear infinite"
                  : "none",
            }}
          >
            <div class="delete" onClick={removefile}>
              <img
                src={require("./delete.png")}
                width="25px"
                height="25px"
                alt=""
                srcset=""
              />
            </div>
            <h3>{fileData.name}</h3>
            <FileSpecView
              data={fileData}
              path={"printstodo"}
              view={true}
              printerProperties={printerProperties}
            ></FileSpecView>
          </div>
        )}
      </div>
    );
  }
  return (
    <div>
      <LoadingView></LoadingView>
    </div>
  );
}

function getPageCount1(file, r) {
  var reader = new FileReader();
  try{
    reader.readAsBinaryString(file);
    reader.onloadend = function () {
      try {
        var count = reader.result.match(/\/Type[\s]*\/Page[^s]/g).length;
        set(child(r,"pagecount"),count)
        return count  
      } catch (e) {
        remove(r)
        alert("invalid file : "+file.name);
        // set(child(r, "pagecount"), 1);
      }
    };
  }catch(e){
    remove(r)
        alert("invalid file : "+file.name);
  }
  
}
async function getPageCount(file, r) {
  try {
      const arrayBuffer = await file.arrayBuffer();
      const pdf = await pdfjsLib.getDocument({ data: arrayBuffer }).promise;
      const pageCount = pdf.numPages;
      set(child(r, "pagecount"), pageCount); // Update your reference with the page count
      return pageCount;
  } catch (error) {
      remove(r);
      alert("Invalid file "+file.name);
  }
}
function uploadFiles(files, printerProperties) {
  const uid = getAuth(app).currentUser.uid;
  if (uid != null) {
    const db = getDatabase();
    files.forEach((file) => {
      const r = push(ref(db, "users/" + uid + "/printstodo"));
      var p = {};
      if (printerProperties.length > 0) {
        for (let [key, values] of Object.entries(printerProperties)) {
          p[values["name"]] = values["values"][0]["name"];
        }
      }
      set(r, {
        name: file.name,
        count: 1,
        link: "",
        pagecount: 0,
        progress: 10,
        properties: p,
      });
      const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];
      if(allowedTypes.includes(file.type)){
        set(child(r,"pagecount"),1)
      }else{
        getPageCount(file, r);
       
      }
      const storage = getStorage(app);
      const storageRef = stref(storage, `uploads/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          set(
            child(r, "progress"),
            parseInt(progress.toFixed(2)) <= 2
              ? 2
              : parseInt(progress.toFixed(2)) - 1
          );
        },
        (error) => {
          console.error("Error uploading file: ", error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            set(child(r, "link"), url);
            set(child(r, "progress"), 100);
          });
        }
      );
    });
  }
}


const checkOrderPaymentStatus = async (orderId,re) => {
  try {
      // List payments for the given order
      const response = await fetch(`https://atomte.com/api/razorpay/${orderId}/payments`, {
        method: 'GET',
    });
      const paymentsData = await response.json();

      if (!paymentsData || paymentsData.items.length === 0) {
          console.log('No payments found for this order');
          return;
      }

      // Check the status of each payment
      paymentsData.items.forEach(payment => {

          if (payment.status === 'captured') {
            
              console.log(`Payment ${payment.id} was successful.`);
              const auth=getAuth(app);
              const uid=auth.currentUser.uid;
              get(re).then((snapshot)=>{
                const data=snapshot.val();
                if(!data) return;
                var left=0
                var filekey=snapshot.key
                set(ref(getDatabase(app),"users/"+uid+"/printsdetails/"+snapshot.key),snapshot.val()).then(()=>{

                  remove(re).then(()=>{
                    // useNavigate("/autoprint/history")
                    const db = getDatabase(app);
                    if(data.printer){
                      const printerRef = ref(db, "Printers/" + data.printer + "/printstodo");

                      const userPrintDetailRef = ref(getDatabase(app), "users/" + uid + "/printsdetails/" + snapshot.key);
                    set(child(userPrintDetailRef, "status"), "Processing");
                    set(child(userPrintDetailRef, "payment_id"), payment.id);
                    set(child(userPrintDetailRef, "printer"), data.printer).then(()=>{
                      const pagecount=data["pagecount"]
                    const count=data["count"]
                    const tv=data["properties"]["sides"]=="Single-sided" ? 1 : 0.5;
                    left+=pagecount*count*tv
                    left+=(pagecount%2==1 && tv==0.5)?0.5:0
                    const printedref=ref(getDatabase(app),"Printers/"+data.printer+"/details/printed")
                    get(printedref).then((snapshot)=>{
                      const printed=snapshot.val()
                      set(ref(getDatabase(app),"Printers/"+data.printer+"/details/printed"),Math.round(left+printed)).then(()=>{
                        const newPrintRef = push(printerRef, {
                          "user": uid,
                          "files": filekey
                        });
                        set(child(userPrintDetailRef, "push_key"), newPrintRef.key);
                      })
                    })
                    })
                    
                    
                    // Push the new data and get the generated key
                    
                    }
                    
                })
                  
              })
              set(child(re,"payment_status"),"Done");
              
            })
          } else if (payment.status === 'failed') {
              set(child(re,"payment_status"),"failed");
              console.log(`Payment ${payment.id} failed.`);
          } else {
            set(child(re,"payment_status"),payment.status);
              console.log(`Payment ${payment.id} status: ${payment.status}`);
          }
      });

  } catch (error) {
      console.error('Error checking order payment status:', error);
  }
};



function getFilesIds(
  setSelectedFiles,
  setSelectedUploadFiles,
  setLoadingView,
  setTotalCost,
  setTotalpages,
  setProgress,navigate
) {
  const db = getDatabase();
  const dbRef = ref(
    db,
    "users/" + getAuth(app).currentUser.uid + "/printstodo"
  );
  setLoadingView(true);
  onValue(dbRef, (snapshot) => {
    var ids = [];
    var uids = [];
    var totalCost = 0;
    var totalprogress = 0;
    var totalpages = 0;
    snapshot.forEach((childSnapshot) => {
      const childKey = childSnapshot.key;
      const childData = childSnapshot.val();
      if (
        childData.name != null &&
        childData.count != null &&
        childData.link != null &&
        childData.pagecount != null &&
        childData.progress != null &&
        childData.properties != null
      ) {

       // if(childData.order_id!=null){
       //   checkOrderPaymentStatus(childData.order_id,child(dbRef,childKey));
       // }
        if (childData["link"] != null && childData["link"] != "") {
          ids.push(childKey);
          totalCost += childData["cost"];
          const pagecount=childData["pagecount"]
          const count=childData["count"]
          const tv=childData["properties"]["sides"]=="Single-sided" ? 1 : 0.5;
          totalpages+=pagecount*count*tv
          totalpages+=(pagecount%2==1 && tv==0.5)?0.5:0

        } else {
          uids.push(childKey);
        }
      } else {
        remove(child(dbRef, childKey));
      }
      // ...
    });
    setTotalCost(Math.round(totalCost*100)/100);
    setProgress(totalprogress);
    setTotalpages(Math.round(totalpages));

    setLoadingView(false);

    setSelectedFiles(ids.reverse());
    setSelectedUploadFiles(uids.reverse());
  });
}

function getUserData(setUserData) {
  const db = getDatabase(app);
  const dataRef = ref(db, "users/" + getAuth(app).currentUser.uid + "/details"); // Replace 'your_database_path' with the path to your data in the Realtime Database

  onValue(dataRef, (snapshot) => {
    const fetchedData = snapshot.val();
    if (fetchedData) {
      setUserData({
        name: fetchedData["name"],
        email: fetchedData["email"],
        id: fetchedData.key,
        image: fetchedData["image"],
        printer: fetchedData["printer"],
      }); // Convert object to array and set state
    } else {
    }
  });
}
class PrintFileData {
  constructor(id, name, count, link, pageCount, progress, properties, cost) {
    this.id = id;
    this.name = name;
    this.count = count;
    this.link = link;
    this.pageCount = pageCount;
    this.progress = progress;
    this.properties = properties;
    this.cost = cost;
  }
}
export default Home;

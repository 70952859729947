// components/FeatureGrid.js
import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRocket, faLightbulb, faChartLine, faCog } from '@fortawesome/free-solid-svg-icons';

function FeatureGrid() {
  const features = [
    { id: 1, title: 'Autoprint', description: 'Efficient printing solutions for your business', link: '/autoprint', icon: faRocket, color: '#FF6B6B' },
    { id: 1, title: 'Autowash', description: '', link: '/upcomming', icon: faChartLine, color: '#FF6B6B' },
    { id: 1, title: 'Autoscan', description: '', link: '/upcomming', icon: faLightbulb, color: '#FF6B6B' },
    { id: 1, title: 'Autoformat', description: '', link: '/upcomming', icon: faCog, color: '#FF6B6B' }
  ];

  return (
    <section className="feature-grid">
      {features.map((feature) => (
        <Link to={feature.link} key={feature.id} className="feature-item">
          <div className="feature-icon">
            <FontAwesomeIcon icon={feature.icon} />
          </div>
          <h2>{feature.title}</h2>
          <p>{feature.description}</p>
        </Link>
      ))}
    </section>
  );
}

export default FeatureGrid;
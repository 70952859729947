import RegistrationPage from "./Views/RegistrationForm/registrationform";



function Registration(args){
    return(
        <div>
            <RegistrationPage backpath={args.backpath}/>
        </div>
    );
}

export default Registration;
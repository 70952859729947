import React from 'react';
import {useState} from 'react'
import { Link,useNavigate } from 'react-router-dom';
import { GoogleLoginButton } from 'react-social-login-buttons';
import app from '../../../../database/firebase.js'
import { getAuth,signInWithPopup, GoogleAuthProvider, signInWithEmailAndPassword,getAdditionalUserInfo} from "firebase/auth";
import { getDatabase, ref, set } from "firebase/database";
import '../RegistrationForm/registrationform.css'; // Import CSS file for styling
import Loading from '../../../../Views/Loading/loading'



function LoginPage(args) {
  const [lodingView,setLoadingView]=useState(false);
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  
  const nav=useNavigate();
  const provider = new GoogleAuthProvider();
  provider.setCustomParameters({
    prompt: 'select_account'
  });
  const [googleloginloading,setGoogleLoginLoading]=useState(false);
  function logintWithGoogle(){
    setGoogleLoginLoading(true)
    setLoadingView(true)
    const auth = getAuth(app);
    signInWithPopup(auth, provider)
    .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;
        const db = getDatabase();
        const data=getAdditionalUserInfo(result);
        if(data["isNewUser"]){
          set(ref(db, 'users/' + user.uid+"/details"), {
            name: data["profile"]["given_name"]+data["profile"]["family_name"],
            email: data["profile"]["email"],
            image : data["profile"]["picture"]
          }).then(()=>{
            nav(args.backpath);
            setGoogleLoginLoading(false)
            setLoadingView(false);
          }).catch((error) => {
            setGoogleLoginLoading(false)
            setLoadingView(false);
          });
        }else{
          setGoogleLoginLoading(false)
          nav(args.backpath);
          setLoadingView(false);
        }
        
        //alert("user: " + user.email);
        // IdP data available using getAdditionalUserInfo(result)
        // ...
    }).catch((error) => {
        // Handle Errors here.
        setGoogleLoginLoading(false)
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
    });
  }
  const logIn=() => {
    if(formData.email=="" || formData.password==""){
      alert("Please enter all details");
      return;
    }
    setLoadingView(true);
    
    const auth = getAuth(app);
    signInWithEmailAndPassword(auth, formData.email, formData.password)
      .then((userCredential) => {
        const user = userCredential.user;
        setLoadingView(false);
        nav(args.backpath);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        setLoadingView(false);
        alert(errorMessage);

        // ..
      });
  };
  const handleChange = (e) => {
    e.preventDefault();
    setFormData({...formData, [e.target.name]: e.target.value });
  };

 const goto=()=>{
  nav(args.backpath+"anlogin");
 }
  return (
    <div>
      {lodingView ? <Loading/> : 
      <div className="registration-container">
        <div className="registration-form">
        <h2>Login</h2>
        <div>
          <div className="form-group">
            <label htmlFor="email"><i className="fas fa-envelope"></i> Email</label>
            <input type="email" id="email" name="email" value={formData.email} onChange={handleChange}/>
          </div>
          <div className="form-group">
            <label htmlFor="password"><i className="fas fa-lock"></i> Password</label>
            <input type="password" id="password" name="password" value={formData.password} onChange={handleChange}/>
          </div>
          <div>
            <button onClick={logIn} className="btn-signup"><i className="fas fa-user-plus"></i> Log In</button>
          </div>
        </div>
        <p>---or---</p>
        <GoogleLoginButton
      className="btn-google"
      onClick={logintWithGoogle}
      disabled={googleloginloading} // Disable button if loading
    >
      {googleloginloading ? (
        <>
        <div className='google-btn-loading'>
             <span className="spinner"></span> Loading...
        </div>
          
        </>
      ) : (
        "Login with Google"
      )}
    </GoogleLoginButton>
        <p className="login-link">Create an Account? <Link to={args.backpath+"registration"}>Sign Up</Link></p>
        <p>---or---</p>
        <div className='cont-btn' onClick={goto}>
        <button className="continue-button">
        Continue without account
        <span className="continue-button-arrow">→</span>
      </button>
      </div>
      </div>
    </div>}
    </div>
  );
}


export default LoginPage;

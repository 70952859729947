import React, { useState, useEffect } from "react";
import "./fileitem.css";
import { FaPlus, FaMinus } from "react-icons/fa";
import { FaRupeeSign } from "react-icons/fa";
import app from '../../../../database/firebase';
import { getDatabase,ref, set,onValue,child, get } from 'firebase/database';
import { getAuth } from "firebase/auth";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';

function FileSpecView(args) {
  
  const [data, setData] = useState(args.data);
  const [printerProperties, setPrinterProperties] = useState([]);
  useEffect(() => {
    setData(args.data);
  }, [args.data]);
  useEffect(() => {
    setPrinterProperties(args.printerProperties);
  },[args.printerProperties])
  
  const [options, setOptions] = useState({});
  const r = ref(getDatabase(app), `users/${getAuth(app).currentUser.uid}/${args.path}/${data["id"]}/`);
  const totalCost = ()=>{
    var cost=(data["pageCount"] * data["count"])
    var costs={}
    costs[""]=1
    if(printerProperties.length>0){

    for(let [key,values] of Object.entries(printerProperties)){

      for(let [k,v] of Object.entries(values["values"])){
        costs[v["name"]]=v["cost"];
      }

    }
  }
  for(let [key,value] of Object.entries(data["properties"])){
    if(costs[value]===undefined){
      costs[value]=1
    }
    cost*=costs[value];
  }
  set(child(r,"cost"),Math.round(cost*100)/100);
  return Math.round(cost*100)/100;    
    
  }


  const increment = () => {
    set(child(r,"count"),data["count"]+1);
  };
  const decrement = () => {
    
    if (data["count"] > 0) {
        set(child(r,"count"),data["count"]-1);
    }
  };
  const handleOptionChange = (optionName, e) => {
    set(child(r, "properties/"+optionName), e.target.value);
  };
  const [queue, setQueue] = useState(-1);
  useEffect(() => {
    const printerprintstodoRef = ref(getDatabase(app), "Printers/" + data["printer"] + "/printstodo");
    
    onValue(printerprintstodoRef, (snapshot) => {
      if (!snapshot.exists()) return;
      const data1 = snapshot.val();
      if (data1) {
      const pushIds = Object.keys(data1); // Get all push_ids
      // Find the index of the specific push_id
      const position = pushIds.indexOf(data["push_id"]);
      if (position !== -1) {
        setQueue(position);
      } else {
        setQueue(-1);
      }
    }
    });
  },[])
 
  
  return (
    <div className="file-upload">
      <div className="file-info">
        <div className="file-detail">
          <strong>Page Count : </strong> {data["pageCount"]}
        </div>
      </div>
      <div class="prop">
      <div class="count">
        {args.view?(
          <>
        <button className="counter-btn" value={data["count"]}  onClick={decrement}>
        <FontAwesomeIcon icon={faMinus} />
      </button>
      <span className="count">{data["count"]}</span>
      <button className="counter-btn" value={data["count"]}  onClick={increment}>
        <FontAwesomeIcon icon={faPlus} />
      </button>
          </>
      ):(
        <div className="count_u">
          <span className="count_u">{"Count : " + data["count"]}</span>
          <h6>{"Payment Id : " + data["payment_id"]}</h6>
          <p>{"Status : " + data["status"]}</p>
          {data["status"].toLowerCase().includes("error") && (
            <div>
              <p>{"Payment Id : " + data["payment_id"]}</p>
              <button
                className="payment-status-button"
                onClick={() => window.location.href = `https://razorpay.com/support/#refund`}
              >
                Check Refund Status
              </button>
            </div>
          )}

          {
            queue >= 0 &&
            <p>{"Queue Position : "+queue.toString() + " left"}</p>
            }
        </div>
        
      )}</div>
      {(args.view && args.printerProperties.length > 0) ?
    
      <div className="options">
        

        {Object.entries(args.printerProperties).map(([optionName, optionValues]) => (
          <label key={optionName} className="option">
            <select
              value={data["properties"][optionValues["name"]] || "sel"}
              onChange={(e) => handleOptionChange(optionValues["name"], e)}
            >
              
              {optionValues["values"].map((value) => (
                <option key={value["name"]} value={value["name"]}>
                  {value["name"]}
                </option>
              ))}
            </select>
          </label>
        ))}        
        
      </div>:
      <label className="option">
        {Object.entries(data["properties"] || {}).map(([optionName, optionValue]) => (
          (optionName!="-")?<p key={optionName}>
          {optionName}: {optionValue}
        </p>
            :<></>
          
        ))}
      </label>
  }
      </div>
      {args.view?(<div className="progress-bar-container">
        <div className="progress-bar" style={{ width: `${data["progress"]}%` }}>{data["progress"]}%</div>
      </div>):(<div/>)}
      <div className="total-price">
        <strong>Total Price : </strong> <FaRupeeSign /><span className="price">{args.view?totalCost():Math.round(data["cost"]*100)/100}</span> 
      </div>
    </div>
  );
}




export default FileSpecView;

import React from 'react';

function Description() {
  return (
    <section className="description">
      <h1>Explore our services</h1>
      <p>Discover how our innovative solutions can streamline your equipment needs</p>
    </section>
  );
}

export default Description;